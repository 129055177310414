import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Color, { colors } from '../../../primitives/colors';
import Divider from '../../../primitives/divider';
import HoverItemSet from '../../../primitives/hover-item-set';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import FadeTransition from '../../../animations/transitions/fade-transition';
import AspectRatio from '../../../primitives/aspect-ratio';
import MediaItem from '../../../primitives/media-item';
import BasicSlider from '../../../basic-slider';

export type HoverFeatureSetQuoteProps = Queries.HoverFeatureSetQuotePropsFragment;

const HoverFeatureSetQuote = ({ richTitle, cards, link, orientation, colour }: HoverFeatureSetQuoteProps): JSX.Element => {
    const [value, setValue] = React.useState(0);
    return (
        <BasicSlider>
            <Color
                as={Container}
                backgroundColor="shadesWhite"
                css={{
                    marginTop: '120px',
                    hr: {
                        opacity: 0,
                    },
                    '&:not(:last-of-type)': {
                        hr: {
                            opacity: 1,
                        },
                    },
                    [breakpoints.mb]: {
                        marginTop: '70px',
                    },
                }}
            >
                <Row css={{
                    gridAutoFlow: 'column',
                }}
                >
                    <Col breakpoints={{
                        dt: {
                            span: 12,
                            start: 0,
                            hidden: true,
                        },
                        mb: {
                            span: 4,
                            start: 0,
                            hidden: false,
                        },
                    }}
                    >
                        <Typography
                            override
                            css={{
                                paddingBottom: '36px',
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                    maxWidth: '84%',
                                    margin: 'auto',
                                },
                            }}
                            fontWeight="light"
                            fontSize={{
                                dt: 4053,
                                mb: 2429,
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle} />
                        </Typography>
                    </Col>
                    <Col breakpoints={{
                        dt: {
                            span: 6,
                            start: orientation === 'image-on-left' ? 0 : 6,
                        },
                        mb: {
                            span: 4,
                            start: 0,
                        },
                    }}
                    >

                        <div css={{
                            paddingBottom: '70px',
                            height: '100%',
                            [breakpoints.mb]: {
                                paddingBottom: '40px',
                            },
                        }}
                        >
                            <div css={{
                                maxWidth: '425px',
                                marginRight: (orientation === 'image-on-right') ? 'unset' : 'min(88px, 6vw)',
                                marginLeft: orientation === 'image-on-left' ? 'unset' : 'min(88px, 6vw)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                [breakpoints.mb]: {
                                    maxWidth: 'min(70%, 300px)',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    paddingBottom: '26px',
                                },
                            }}
                            >
                                <AspectRatio breakpoints={{ dt: { width: 425, height: 560 } }}>
                                    <div css={{
                                        backgroundColor: colors.primaryDarkPurple,
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: borderRadius.extraLarge,
                                        [breakpoints.tb]: {
                                            borderRadius: borderRadius.semiLarge,
                                        },
                                    }}
                                    />
                                    <FadeTransition
                                        shouldChange={value.toString()}
                                        css={{
                                            width: '100%',
                                        }}
                                    >

                                        <MediaItem
                                            item={cards[value].mediaItem}
                                            css={{
                                                borderRadius: borderRadius.extraLarge,
                                                [breakpoints.tb]: {
                                                    borderRadius: borderRadius.semiLarge,
                                                },
                                                overflow: 'hidden',
                                                transform: 'translateZ(0px)',
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                height: '100%',
                                                justifyContent: 'flex-end',
                                                width: '100%',
                                            }}
                                        />
                                    </FadeTransition>
                                    <div
                                        className="decoration"
                                        css={{
                                            position: 'absolute',
                                            right: (orientation === 'image-on-right') ? 'unset' : 'max(-88px, -6vw)',
                                            left: orientation === 'image-on-left' ? 'unset' : 'max(-88px, -6vw)',
                                            bottom: 'min(36px, 2vw)',
                                            maxWidth: 'min(248px, 60%)',
                                            [breakpoints.tb]: {
                                                bottom: '36px',
                                            },
                                            [breakpoints.mb]: {
                                                left: (orientation === 'image-on-left') ? 'unset' : 'min(-20%, -30px)',
                                                right: orientation === 'image-on-right' ? 'unset' : 'min(-20%, -30px)',
                                                maxWidth: 'min(248px, 80%)',
                                                transform: 'scale(0.9)',
                                            },
                                        }}
                                    >
                                        <Color
                                            backgroundColor="primaryOrange"
                                            color="shadesWhite"
                                            css={{
                                                backgroundColor: `rgba(${colour?.[0]?.colour?.red || '0'}, ${colour?.[0]?.colour?.green || '0'}, ${colour?.[0]?.colour?.blue || '0'}, 1)`,
                                                padding: '18px',
                                                borderRadius: borderRadius.large,
                                                boxShadow: `0 12px 24px 0px rgba(${colour?.[0]?.colour?.red || '0'}, ${colour?.[0]?.colour?.green || '0'}, ${colour?.[0]?.colour?.blue || '0'}, 0.28)`,
                                                width: '100%',
                                                [breakpoints.tb]: {
                                                    borderRadius: borderRadius.semiLarge,
                                                },
                                            }}
                                        >
                                            <BasicSlider.Slides animation="fade" slidesPerView={1}>
                                                {cards?.map(card => (
                                                    <div>
                                                        <Typography
                                                            fontSize={{
                                                                dt: 1822,
                                                                tb: 1420,
                                                            }}
                                                            css={{
                                                                paddingBottom: '24px',
                                                                [breakpoints.mb]: {
                                                                    paddingBottom: '8px',
                                                                },
                                                            }}
                                                            fontWeight="medium"
                                                        >
                                                            <div>&#34;</div>
                                                            <div>
                                                                {card.quote}
                                                            </div>
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </BasicSlider.Slides>
                                            <BasicSlider.Slides animation="fade" slidesPerView={1}>
                                                {cards?.map(card => (
                                                    <div>
                                                        <Typography
                                                            fontSize={{
                                                                dt: 1322,
                                                            }}
                                                            fontWeight="medium"
                                                        >
                                                            {card.attribution}
                                                        </Typography>
                                                        <Typography fontSize={{
                                                            dt: 1019,
                                                        }}
                                                        >
                                                            {card.role}
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </BasicSlider.Slides>
                                        </Color>
                                    </div>
                                </AspectRatio>
                            </div>
                        </div>
                    </Col>
                    <Col breakpoints={{
                        dt: {
                            span: 6,
                            start: orientation === 'image-on-left' ? 6 : 0,
                        },
                        mb: {
                            span: 4,
                            start: 0,
                        },
                    }}
                    >
                        <Typography
                            override
                            css={{
                                paddingBottom: '36px',
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                    maxWidth: '84%',
                                    margin: 'auto',
                                    display: 'none',
                                },
                            }}
                            fontWeight="light"
                            fontSize={{
                                dt: 4053,
                                mb: 2429,
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle} />
                        </Typography>
                        <HoverItemSet
                            cards={cards}
                            link={link}
                            setValue={setValue}
                        />
                    </Col>
                </Row>
                <Divider />
            </Color>
        </BasicSlider>
    );
};
export default HoverFeatureSetQuote;

export const query = graphql`
    fragment HoverFeatureSetQuoteProps on DatoCmsHoverFeatureSetQuote {
        orientation
        richTitle {
            blocks
            links
            value
        }
        cards {
            title
            text
            link {
                ...LinkWithTitleProps
            }
            mediaItem {
                ...MediaItemProps
            }
            quote
            attribution
            role
        }
        link {
            ...LinkWithTitleProps
        }
        colour {
            colour {
                red
                green
                blue
            }
        }
    }
`;
